import { Box, Button, Chip, ListItem, Stack } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import useStore from '../providers/useStore';

export const NavItem = (props) => {
  const { href, icon, iconNew, title, ...others } = props;
  let location = useLocation();
  const active = href ? (location.pathname === href) : false;
  const setIsOpen = useStore((state) => state.setIsOpen)
  
  const ativaProcess = (isOpen) => {
    if(!isOpen){
      setIsOpen(true)
      return
    }
    return
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}
    >
      <Link
        to={href}
        style={{ textDecoration: "none",width: '100%' }}
      >
        <Button
          onClick={() => ativaProcess(active)}
          startIcon={icon}
          disableRipple
          sx={{
            backgroundColor: active && 'rgba(255,255,255, 0.08)',
            borderRadius: 1,
            color: active ? 'secondary.main' : 'neutral.300',
            fontWeight: active && 'fontWeightBold',
            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '& .MuiButton-startIcon': {
              color: active ? 'secondary.main' : 'neutral.400'
            },
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Box>
                {title}
              </Box>
             {iconNew && <Chip label="Novo" size="small" color="success" />} 
            </Stack>
          </Box>
        </Button>
      </Link>
    </ListItem>
  );
};

