import { useQuery } from "react-query";
import api from "../../service/api";

async function getUnidades() {
  const { data } = await api.get('/empresas')
  return data
}

export default function useFetchGetUnidades() {
    return useQuery(['empresas'], getUnidades, { refetchOnWindowFocus: false})
}