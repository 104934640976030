import { Alert, Backdrop, Button, Card, Chip, CircularProgress, Grid, IconButton, Pagination, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { HeaderPage } from "../components/header-page";
import { Layout } from "../components/layout";
import AddCardIcon from '@mui/icons-material/AddCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useFetchGetParcelasSalvas from "../queries/parcelas";
import { useState } from "react";
import moment from "moment/moment";
import { StatusParcela } from "../components/StatusParcela";
import ModalBuscarAluno from "../components/ModalBuscarAluno";
import DeleteIcon from '@mui/icons-material/Delete';
import usePlataforma from "../hooks/usePlataforma";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { InputSearchQuery } from "../components/InputSearchQuery";

export const Parcelas = () => {
  const [page, setPage] = useState(1);
  const [situacao, setSituacao] = useState("Todas");
  const [open, setOpen] = useState(false);
  const { deletParcela } = usePlataforma()
  const [carregando, setCarregando] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [filterStartDate, setFilterStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [filterEndDate, setFilterEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [cpf, setCpf] = useState('');
  
  const handleChange = (event, value) => {
    setPage(value);
  }

  const { isLoading, data } = useFetchGetParcelasSalvas(situacao, page, filterStartDate, filterEndDate, cpf)

  async function deleteParcela(id) {
    setCarregando(true)
    await deletParcela(id)
    setCarregando(false)
  }

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
    setEndDate('');
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    setEndDate(selectedDate);
    setFilterStartDate(startDate)
    setFilterEndDate(selectedDate)
  };

  return (
    <Layout isLoading={isLoading}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{mb: 5}}
      >
        <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
      <HeaderPage title="Parcelas" />
      <Chip size="small" label={data ? data.totalDocs : 0} />
      </Stack>
      <Button onClick={() => setOpen(true)} variant="contained" startIcon={<AddCardIcon/>} >Adicionar</Button>
      </Stack>
        <Card sx={{mb: 4}}>
        <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{p: 2}}
      >
        <Grid container spacing={2}>
        <Grid item xs={1}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={2}
        >
        <Typography>Período</Typography>
        </Stack>
        </Grid>
        <Grid item xs={2}>
        <TextField
        fullWidth
        size="small" 
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }} 
        label="De" 
        variant="outlined"
         />
        </Grid>
        <Grid item xs={2}>
        <TextField
        fullWidth
        disabled={!startDate}
        label="Até"
        type="date"
        size="small" 
        variant="outlined"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: startDate
        }}
      />
        </Grid>
        <Grid item xs={4}>
        <InputSearchQuery setQuery={setCpf} label="Buscar CPF"/>
        </Grid>
        </Grid>
      </Stack>
        </Card>
        <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        sx={{p: 1.5}}
      >
        <Chip size="small" icon={<ErrorIcon />} label={`${data ? data.counts.countPendente : 0} ${data && data.counts.countPendente > 0 ? 'Pendentes' : 'Pendente'}`} />
        <Chip size="small" icon={<CheckCircleIcon />} label={`${data ? data.counts.countQuitada : 0} ${data && data.counts.countQuitada > 0 ? 'Quitadas' : 'Quitada'}`} />
        <Chip size="small" icon={<HighlightOffIcon />} label={`${data ? data.counts.countCancelada : 0} ${data && data.counts.countCancelada > 0 ? 'Canceladas' : 'Cancelada'}`} />
      </Stack>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><Tooltip placement="top" title="Parcela" arrow><Typography sx={{cursor: "default"}}>#</Typography></Tooltip></TableCell>
            <TableCell>Aluno</TableCell>
            <TableCell>Categoria</TableCell>
            <TableCell>Valor Parcela</TableCell>
            <TableCell>Valor Pago</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Situação</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {data && data.docs.map(current => (
              <TableRow
              hover
              key={current._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{current.NumeroParcela}</TableCell>
              <TableCell>{current.Sacado}</TableCell>
            <TableCell>{current.Categoria}</TableCell>
            <TableCell>R$ {current.ValorParcela}</TableCell>
            <TableCell>R$ {current.ValorPago ? current.ValorPago : "0,00"}</TableCell>
            <TableCell>{moment(current.Vencimento).format("DD/MM/YYYY")}</TableCell>
            <TableCell><StatusParcela status={current.SituacaoParcela} /></TableCell>
            <TableCell>
              <IconButton onClick={() => deleteParcela(current._id)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
      {data && data.docs.length === 0 && <Alert severity="warning">Nenhum registro encontrado!</Alert> }
    </TableContainer>
    <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mb: 3 }}
      >
       {data && data.totalPages > 1 && <Pagination count={data ? data.totalPages : 1} page={page} onChange={handleChange} variant="outlined" shape="rounded" color='primary' />} 
      </Stack>
      <ModalBuscarAluno open={open} setOpen={setOpen} />
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={carregando || isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </Layout>
  );
}
