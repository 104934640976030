import { Avatar, Box, Card, Chip, Stack, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export const CardRank = ({ title, parcelas, valor, lugar }) => {
  
  let color, icon;
  
  switch (lugar) {
    case 0:
      color = "secondary.dark";
      icon = <StarIcon />;
      break;
    case 1:
      color = "secondary.main";
      icon = <StarHalfIcon />;
      break;
    case 2:
      color = "secondary.light";
      icon = <StarBorderIcon />;
      break;
    default:
      color = "primary.light";
      icon = <ErrorOutlineIcon />;
      break;
  }

  function formatarMoeda(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
  
    return formatter.format(valor);
  }

  function pegarPrimeiroNome(nomeCompleto) {
    var nomes = nomeCompleto.split(" ");
    var primeiroNome = nomes[0].toLowerCase();
    primeiroNome = primeiroNome.charAt(0).toUpperCase() + primeiroNome.substring(1);
    return primeiroNome;
  }

  return (
    <Card sx={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ p: 2, width: "100%" }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
        >
          <Stack
  direction="row"
  justifyContent="flex-start"
  alignItems="center"
  spacing={0.5}
>
<Chip color="success" size="small" variant="filled" label={`${lugar + 1}º Lugar`} />
<Chip size="small" label={`${parcelas} ${parcelas > 1 ? "Parcelas" : "Parcela"}`} />
<Chip size="small" label={formatarMoeda(valor)} />
</Stack>
          <Typography color="textPrimary" variant="h4">
            {pegarPrimeiroNome(title)}
          </Typography>
        </Stack>

        <Stack
  direction="row"
  justifyContent="flex-end"
  alignItems="center"
  sx={{ width: "100%", pr: 2 }}
>
        <Avatar
          sx={{
            backgroundColor: color,
            height: 48,
            width: 48,
          }}
        >
          {icon}
        </Avatar>
</Stack>
<Box sx={{ width: 30, height: 10 }} />
      </Stack>
    </Card>
  );
};
