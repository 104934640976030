import { useQuery } from "react-query";
import api from "../../service/api";

async function getParcelasSalvas(ctx) {
  const [, SituacaoParcela, page, dataInicialcreatedAt, dataFinalcreatedAt, cpf] = ctx.queryKey
  const { data } = await api.get('/salvas', { params: { SituacaoParcela, page, dataInicialcreatedAt, dataFinalcreatedAt, cpf } })
  return data
}

export default function useFetchGetParcelasSalvas(SituacaoParcela, page, dataInicialcreatedAt, dataFinalcreatedAt, cpf) {
    return useQuery(['salvas', SituacaoParcela, page, dataInicialcreatedAt, dataFinalcreatedAt, cpf], getParcelasSalvas, { refetchOnWindowFocus: false})
}