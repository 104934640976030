import { toast } from "react-toastify";
import api from "../service/api"
import { useQueryClient } from "react-query";

const usePlataforma = () => {
    const queryClient = useQueryClient();

    const addMeta = async (resultadoDia, resultadoSemana, resultadoMes) => {
        try {
            await api.post("/add/meta", { resultadoDia, resultadoSemana, resultadoMes })
            queryClient.invalidateQueries(['meta'])
        } catch (error) {
            console.log(error)
        }
    }

    const deletParcela = async (id) => {
        try {
            await api.post("/delete/parcela", { id })
            queryClient.invalidateQueries(['salvas'])
        } catch (error) {
            console.log(error)
        }
    }

    const registrarParcela = async (parcela) => {
        try {
            await api.post("/registrar", parcela)
            queryClient.invalidateQueries(['salvas'])
            return { error: false }
        } catch (error) {
            console.log(error)
            return { error: true }
        }
    }

    const addUser = async (nome, email) => {
        try {
            await api.post("/add/usuario", { nome, email })
            queryClient.invalidateQueries(['usuarios'])
        } catch (error) {
            console.log(error)
        }
    }

    const aluno = async (cpf) => {
        try {
            const { data } = await api.get("/aluno", { params: { cpf } })
            return { error: false, data: data.aluno.AlunoID, nome: data.aluno.Nome}
        } catch (error) {
            toast.error(error.response.data.message)
            return { error: true }
        }
    }
    
    return {
        addMeta,
        addUser,
        aluno,
        registrarParcela,
        deletParcela
    };
}

export default usePlataforma;
