import React, { useState, useRef } from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export const InputSearchQuery = ({ label, setQuery }) => {
  const [value, setValue] = useState('');
  const [search, setSearch] = useState(false);
  const prevValueRef = useRef('');

  const handleSearch = () => {
    if (search) {
      setQuery('');
      setValue('');
      setSearch(false);
    } else {
      setQuery(value);
      setSearch(true);
    }
  };

  const changeValue = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (newValue.length === prevValueRef.current.length - 1) {
      setQuery('');
    }
    if (newValue === '') {
      setSearch(false);
    }
    prevValueRef.current = newValue;
  };

  return (
    <TextField
      size='small'
      variant="outlined"
      label={label}
      value={value}
      onChange={changeValue}
      InputProps={{
        endAdornment: (
          <>
            {search ? (
              <SearchOffIcon
                sx={{ cursor: 'pointer' }}
                onClick={handleSearch}
                color="error"
              />
            ) : (
              <SearchIcon
                sx={{ cursor: 'pointer' }}
                onClick={handleSearch}
              />
            )}
          </>
        ),
      }}
    />
  );
};
