import { Alert, Backdrop, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { HeaderPage } from "../components/header-page";
import { Layout } from "../components/layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetchGetAlunoID from "../queries/buscarParcelaID";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { StatusParcela } from "../components/StatusParcela";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from "react";
import usePlataforma from "../hooks/usePlataforma";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { ButtonEscolha } from "../components/ButtonEscolha";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const AddParcela = () => {
  const { AlunoID } = useParams();
  const location = useLocation();
  const state = location.state;
  const [carregando, setCarregando] = useState(false);
  const { data, isLoading } = useFetchGetAlunoID(AlunoID)
  const { registrarParcela } = usePlataforma()
  let navigate = useNavigate();

  async function handleSelect(parcela) {
      setCarregando(true)
      const { error } = await registrarParcela(parcela)
      if(error) {
        toast.error("Parcela já cadastrada!")
        setCarregando(false)
        return
      }
      toast.success("Parcela registrada com sucesso!")
      setCarregando(false)
  }

  function handleVoltar() {
    navigate("/parcelas", { replace: true });
  }

  return (
    <Layout isLoading={isLoading}>
      <Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}
>
  <Box>
  <HeaderPage title="Escolha uma parcela" />
      <Typography color="textSecondary" gutterBottom variant="overline">
        <strong>Aluno:</strong> {state ? state : ""}
      </Typography>
  </Box>
<Button variant="contained" color="success" onClick={handleVoltar} startIcon={<CheckCircleIcon />}>FINALIZAR</Button>
</Stack>
      
      <Box sx={{ mt: 2, mb: 2}}>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Parcela</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Valor Parcela</TableCell>
            <TableCell>Categoria</TableCell>
            <TableCell>Situação</TableCell>
            <TableCell>Opções</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {data && data.parcelas.map(current => (
              <TableRow
              hover
              key={`${current.ContaReceberID}${current.NumeroParcela}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{current.NumeroParcela}</TableCell>
            <TableCell>{moment(current.Vencimento).format("DD/MM/YYYY")}</TableCell>
            <TableCell>R$ {current.ValorParcela}</TableCell>
            <TableCell>{current.Categoria}</TableCell>
            <TableCell><StatusParcela status={current.SituacaoParcela}/></TableCell>
            <TableCell><ButtonEscolha handleSelect={handleSelect} parcela={current} existe={current.existe} adicionadoPor={current.adicionadoPor} /></TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
      {!data && !isLoading && <Alert severity="warning">Nenhuma parcela encontrada!</Alert> }
    </TableContainer>
    <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={carregando}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

      </Box>
    </Layout>
  );
}
