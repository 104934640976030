import create from 'zustand'

const useStore = create((set) => ({
  token: '',
  user: null,
  isOpen: false,
  setToken: (token) => set({ token }),
  setUser: (user) => set({ user }),
  setIsOpen: (isOpen) => set({ isOpen })
}))

export default useStore