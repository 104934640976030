import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Backdrop, CircularProgress, Stack, TextField } from '@mui/material';
import usePlataforma from '../hooks/usePlataforma';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalBuscarAluno({ setOpen, open }) {
    const { aluno } = usePlataforma()
    const [CPF, setCPF] = React.useState('');
    const [carregando, setCarregando] = React.useState(false);

    let navigate = useNavigate();

    const handleClose = () => {
    setOpen(false);
    setCPF("")
  };


  const salvar = async () => {
    setCarregando(true)
    const data = await aluno(CPF)
    setCarregando(false)
    handleClose()
    if (!data.error) {
      navigate(`/parcelas/${data.data}`, { state: data.nome });
    }
  }

  return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Buscar aluno
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Typography gutterBottom>
            CPF
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{mt: 1, mb: 2}} 
            >
          <TextField value={CPF} onChange={event => setCPF(event.target.value)} sx={{width: 500}} size='small' placeholder='Escreva aqui...' fullWidth variant="outlined" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={!Boolean(CPF)} variant='contained' color="success" autoFocus onClick={salvar}>
            Buscar
          </Button>
        </DialogActions>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={carregando}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
      </BootstrapDialog>
  );
}
