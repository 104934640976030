import { Chip } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HelpIcon from '@mui/icons-material/Help';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const StatusParcela = ({ status }) => {
    let color, icon;

  switch (status) {
    case "Quitada":
      color = "success";
      icon = <CheckCircleIcon />;
      break;
    case "Excluída":
      color = "error";
      icon = <RemoveCircleIcon />;
      break;
    case "Pendente":
      color = "warning";
      icon = <ErrorIcon />;
      break;
    case "Cancelada":
      color = "error";
      icon = <HighlightOffIcon />;
      break;
    default:
      color = "default";
      icon = <HelpIcon />;
      break;
  }

  return <Chip sx={{px: 1, borderRadius: 1, minWidth: 108}} icon={icon} size="small" label={status} color={color} />;
};
