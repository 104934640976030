import { Box, Grid, Typography } from "@mui/material";
import { HeaderPage } from "../components/header-page";
import { Layout } from "../components/layout";
import { CardInfo } from "../components/CardInfo";
import useFetchGetRelatorio from "../queries/relatorio";
import moment from "moment";
import { CardMeta } from "../components/CardMeta";
import useFetchGetMetas from "../queries/meta";

export const Dashboard = () => {

  const { data, isLoading } = useFetchGetRelatorio()
  const { data: dataMetas, isLoading: isLoadingMetas } = useFetchGetMetas()

  return (
    <Layout isLoading={isLoading && isLoadingMetas}>
      <HeaderPage title="Dashboard" />
      <Box sx={{ mt: 2 }} >
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CardInfo title="DIA" valor={data ? data.docs.valorDiaFormatted : "R$ 0,00"} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CardInfo title="SEMANA" valor={data ? data.docs.valorSemanaFormatted : "R$ 0,00"} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CardInfo title="MÊS" valor={data ? data.docs.ValorMesFormatted : "R$ 0,00"} />
          </Grid>
        </Grid>
        
      </Box>
      <Box sx={{ mt: 2 }} >
        <CardMeta progress={dataMetas ? dataMetas.porcentagemDia : 0} valor={dataMetas ? dataMetas.meta.resultadoDia : 0} title="do dia" />
      </Box>
      <Box sx={{ mt: 2 }} >
        <CardMeta progress={dataMetas ? dataMetas.porcentagemSemana : 0} valor={dataMetas ? dataMetas.meta.resultadoSemana : 0} title="da semana" />
      </Box>
      <Box sx={{ mt: 2, mb: 1 }} >
        <CardMeta progress={dataMetas ? dataMetas.porcentagemMes : 0} valor={dataMetas ? dataMetas.meta.resultadoMes : 0} title="do mês" />
      </Box>
      <Box sx={{ mt: 1 }} >
      <Typography color="textSecondary" gutterBottom variant="overline">
          {data ? `Atualizado ${moment(data.docs.createdAt).format("DD/MM HH:mm")}` : "----"}
        </Typography>
      </Box>
    </Layout>
  );
}
