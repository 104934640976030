import { Alert, Button, Stack, TextField } from "@mui/material";
import { Layout } from "../components/layout";
import usePlataforma from "../hooks/usePlataforma";
import { HeaderPage } from "../components/header-page";
import { toast } from "react-toastify";
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useFetchGetUsuarios from "../queries/usuarios";
import PasswordInput from "../components/PasswordInput";

export const Usuarios = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const { addUser } = usePlataforma()

  const changeNome = (event) => {
    setNome(event.target.value)
  }

  const changeEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleSalvar = () => {
    addUser(nome, email.toLowerCase().replace(/\s/g, ''))
    setNome("")
    setEmail("")
    toast.success("Usuários salvo com sucesso!")
  }

  const { data, isLoading } = useFetchGetUsuarios()

  return (
    <Layout isLoading={isLoading}>
      <HeaderPage title="Usuários" />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mt: 4 }}
      >
        <TextField size="small" value={nome} onChange={changeNome} label="Nome" variant="outlined" />
        <TextField size="small" type="email" value={email} onChange={changeEmail} label="Email" variant="outlined" />
        <Button disabled={!Boolean(nome) && !Boolean(email)}  onClick={handleSalvar} size="medium" variant="contained">Salvar</Button>
      </Stack>
      <TableContainer sx={{mt:4, mb: 3}} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Senha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {data && data.docs.map(current => (
              <TableRow
              hover
              key={current._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{current.nome}</TableCell>
            <TableCell>{current.email}</TableCell>
            <TableCell><PasswordInput senha={current.senha}/></TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
      {data && data.docs.length === 0 && <Alert severity="warning">Nenhum registro encontrado!</Alert> }
    </TableContainer>
    </Layout>
  );
}
