import { Alert, Box, Button, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { HeaderPage } from "../components/header-page";
import { Layout } from "../components/layout";
import { CardRank } from "../components/CardRank";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from "react";
import useFetchGetRank from "../queries/rank";
import useStore from "../providers/useStore";
import ConfettiComponent from "../components/ConfettiComponent";
import moment from "moment/moment";

export const Rank = () => {
  const user = useStore((state) => state.user);
  const [situacaoParcela, setSituacaoParcela] = useState("Quitada");
  const [filterData, setFilterData] = useState(moment().format("YYYY-MM-DD"));
  const { data, isLoading } = useFetchGetRank(situacaoParcela, filterData)

  return (
    <Layout isLoading={isLoading}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <HeaderPage title="Classificação" />
        {data && data.resultadoDia.length > 0 && data.resultadoDia[0]._id === user._id && <ConfettiComponent isConfettiActive={true} /> }
        {data && data.resultadoSemana.length > 0 && data.resultadoSemana[0]._id === user._id && <ConfettiComponent isConfettiActive={true} /> }
        {data && data.resultadoMes.length > 0 && data.resultadoMes[0]._id === user._id && <ConfettiComponent isConfettiActive={true} /> }
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Button onClick={() => setSituacaoParcela("Quitada")} variant={situacaoParcela === "Quitada" ? "contained" : "outlined"} color={situacaoParcela === "Quitada" ? "error" : "btn"} startIcon={<FilterAltIcon />}>
            Filtrar Quitadas
          </Button>
          <Button onClick={() => setSituacaoParcela("Pendente")} variant={situacaoParcela === "Pendente" ? "contained" : "outlined"} color={situacaoParcela === "Pendente" ? "error" : "btn"} startIcon={<FilterAltIcon />}>
            Filtrar Todas
          </Button>
          <TextField onChange={(event) => { setFilterData(event.target.value) }} value={filterData} size="small" label="Filtrar Data" variant="outlined" type="date"/>
        </Stack>
      </Stack>

      <Box sx={{ mt: 5 }}>
        <Typography color="textSecondary" gutterBottom variant="overline">
          Classificação parcelas no Mês
        </Typography>
        <Grid sx={{mt:0.5}} container spacing={2} >
         {data && data.resultadoMes.length === 0 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Alert severity="warning">Nenhum registro encontrado!</Alert>
          </Grid>}
          
          {data && data.resultadoMes.map((current, i) => (
            <Grid key={current._id} item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CardRank title={current.nome} lugar={i} parcelas={current.totalRegistros} valor={situacaoParcela === "Quitada" ? current.totalValorPago : current.valorParcela} />
          </Grid>
          ))}

        </Grid>
      </Box>
      <Divider
        sx={{
          borderColor: '#d3d3d3',
          my: 3
        }}
      />
      <Box >
        <Typography color="textSecondary" gutterBottom variant="overline">
          Classificação parcelas na semana
        </Typography>
        <Grid sx={{mt:0.5}} container spacing={2} >
        {data && data.resultadoSemana.length === 0 &&  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Alert severity="warning">Nenhum registro encontrado!</Alert>
          </Grid>}

          {data && data.resultadoSemana.map((current, i) => (
            <Grid key={current._id} item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CardRank title={current.nome} lugar={i} parcelas={current.totalRegistros} valor={situacaoParcela === "Quitada" ? current.totalValorPago : current.valorParcela} />
          </Grid>
          ))}

        </Grid>
      </Box>
      <Divider
        sx={{
          borderColor: '#d3d3d3',
          my: 3
        }}
      />
      <Box >
        <Typography color="textSecondary" gutterBottom variant="overline">
          Classificação parcelas no dia
        </Typography>
        <Grid sx={{mt:0.5}} container spacing={2} >
        {data && data.resultadoDia.length === 0 &&  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Alert severity="warning">Nenhum registro encontrado!</Alert>
          </Grid> }

          {data && data.resultadoDia.map((current, i) => (
            <Grid key={current._id} item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CardRank title={current.nome} lugar={i} parcelas={current.totalRegistros} valor={situacaoParcela === "Quitada" ? current.totalValorPago : current.valorParcela} />
          </Grid>
          ))}

        </Grid>
      </Box>
    </Layout>
  );
}
