import { useQuery } from "react-query";
import api from "../../service/api";

async function getRelatorio() {
  const { data } = await api.get('/relatorio')
  return data
}

export default function useFetchGetRelatorio() {
    return useQuery(['relatorio'], getRelatorio, { refetchOnWindowFocus: false})
}