import { Avatar, Card, Stack, Typography } from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';

export const CardInfo = ({ title, valor}) => {
  return (
    <Card>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ p: 2 }}
      >
        <Avatar
          sx={{
            backgroundColor: "error.main",
            height: 56,
            width: 56,
          }}
        >
          <ReceiptIcon />
        </Avatar>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography color="textSecondary" gutterBottom variant="overline">
            Recebido {title}
          </Typography>
          <Typography color="textPrimary" variant="h5">
            {valor}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
