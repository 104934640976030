import React, { useState, useEffect } from 'react';
import Confetti from 'react-dom-confetti';

const ConfettiComponent = ({ isConfettiActive }) => {
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const [confettiActive, setConfettiActive] = useState(false);

  useEffect(() => {
    setConfettiActive(isConfettiActive);
  }, [isConfettiActive]);

  useEffect(() => {
    let timer;
    if (confettiActive) {
      timer = setTimeout(() => {
        setConfettiActive(false);
      }, 7000);
    }
    return () => clearTimeout(timer);
  }, [confettiActive]);

  return (
    <div>
      <Confetti active={confettiActive} config={config} />
    </div>
  );
};

export default ConfettiComponent;
