import { useQuery } from "react-query";
import api from "../../service/api";

async function getAlunoID(ctx) {
  const [, AlunoID] = ctx.queryKey
  const { data } = await api.get('/parcelas', { params: { AlunoID } })
  return data
}

export default function useFetchGetAlunoID(AlunoID) {
    return useQuery(['parcelas', AlunoID], getAlunoID, { refetchOnWindowFocus: false, retry: 0 })
}