import { Routes, Route, BrowserRouter } from "react-router-dom";
import useStore from "../providers/useStore";
import { useJwt } from "react-jwt";
import { useEffect } from "react";

import { Login } from "../pages/Login";
import { Dashboard } from "../pages/Dashboard";
import { Parcelas } from "../pages/Parcelas";
import { Rank } from "../pages/Rank";
import { Metas } from "../pages/Metas";
import { Usuarios } from "../pages/Usuarios";
import { AddParcela } from "../pages/AddParcela";

export function Rotas() {
  const token = useStore((state) => state.token);
  const setUser = useStore((state) => state.setUser);
  const { decodedToken } = useJwt(token);

  useEffect(() => {
    if (decodedToken && !!token) {
      setUser(decodedToken);
    }
  }, [token, decodedToken, setUser]);

  const renderRoutes = (routes) => {
    return (
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    );
  };

  const privateRoutes = [
    { path: "/", element: <Dashboard /> },
    { path: "/login", element: <Dashboard /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/parcelas", element: <Parcelas /> },
    { path: "/rank", element: <Rank /> },
    { path: "/meta", element: <Metas /> },
    { path: "/usuarios", element: <Usuarios /> },
    { path: "/parcelas/:AlunoID", element: <AddParcela /> },
  ];

  const publicRoutes = [
    { path: "/", element: <Login redirect={false} /> },
    { path: "*", element: <Login redirect={true} /> },
  ];

  return (
    <BrowserRouter>
       {!!token ? renderRoutes(privateRoutes) : renderRoutes(publicRoutes)}
    </BrowserRouter>
  );
}
