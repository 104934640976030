import axios from "axios";

const api = axios.create({
  baseURL: "https://apiplaylist.fastsender.cloud/sponte",
  // baseURL: "http://localhost:3001/sponte",
  headers: {
    "Content-Type": "application/json"
  },
});

export default api