import { useQuery } from "react-query";
import api from "../../service/api";

async function getRank(ctx) {
  const [, SituacaoParcela, filterData] = ctx.queryKey
  const { data } = await api.get('/rank', { params: { SituacaoParcela, filterData } })
  return data
}

export default function useFetchGetRank(SituacaoParcela, filterData) {
    return useQuery(['rank', SituacaoParcela, filterData], getRank, { refetchOnWindowFocus: false})
}