import styled from '@emotion/styled';
import { AppBar, Badge, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React from 'react';
import { useNavigate } from "react-router-dom";
import useStore from '../providers/useStore';
import { toast } from 'react-toastify';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;
  const setToken = useStore((state) => state.setToken)

 

  const handleNotif = () => {
    toast.info("Notificações estarão disponíveis em breve.")
  }


  let navigate = useNavigate();

  const handleLogout = () => {
    setToken('')
    navigate("/login", { replace: true });
  }

  
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Notificações" >
          <IconButton
          onClick={handleNotif}
          size="small"
          sx={{ ml: 1 }}
          
          >
          <Badge color="error" badgeContent={0}>
            <NotificationsIcon />
          </Badge>
          </IconButton>
          </Tooltip>
          <Tooltip title="Sair" >
            <IconButton sx={{ ml: 1 }} onClick={() => handleLogout()}>
              <LogoutIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

