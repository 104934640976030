import { useQuery } from "react-query";
import api from "../../service/api";

async function getMetas() {
  const { data } = await api.get('/meta')
  return data
}

export default function useFetchGetMetas() {
    return useQuery(['meta'], getMetas, { refetchOnWindowFocus: false})
}