import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import LinearWithValueLabel from "./LinearProgressWithLabel";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';


export const CardMeta = ({ valor, progress, title }) => {

    var progressFormatted = parseInt(progress) >= 100 ? 100 : progress


    function formatarMoeda(valor) {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
        });
      
        return formatter.format(valor);
      }

    return (
        <Card>
            <Box sx={{p: 2}}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Stack
  direction="row"
  justifyContent="flex-start"
  alignItems="center"
  spacing={2}
>
<Avatar
          sx={{
            backgroundColor: "error.main",
            height: 30,
            width: 30,
          }}
        >
          <CrisisAlertIcon />
        </Avatar>

                    <Typography color="textSecondary" gutterBottom variant="h6">
                        META {title.toUpperCase()}
                    </Typography>
</Stack>
                    <Typography color="textPrimary" variant="h5">
                        {formatarMoeda(valor)}
                    </Typography>
                </Stack>
                <Box sx={{ mt: 2 }}>
                    <LinearWithValueLabel progress={progressFormatted} />
                </Box>
            </Box>
        </Card>
    );
}
