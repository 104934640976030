import { Backdrop, Box, CircularProgress, Container } from "@mui/material";
import { useEffect } from "react";
import useStore from "../providers/useStore";
import { DashboardLayout } from "./dashboard-layout";

export const Layout = ({ isLoading, children }) => {
  const setIsOpen = useStore((state) => state.setIsOpen);

  useEffect(() => {
    setIsOpen(isLoading)
  }, [isLoading, setIsOpen]);
  
  return (
    <DashboardLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            {children}
          </Container>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DashboardLayout>
  );
}
