import { useQuery } from "react-query";
import api from "../../service/api";

async function getUsuarios() {
  const { data } = await api.get('/usuarios')
  return data
}

export default function useFetchGetUsuarios() {
    return useQuery(['usuarios'], getUsuarios, { refetchOnWindowFocus: false})
}