import { Box, Button, Stack, TextField } from "@mui/material";
import { HeaderPage } from "../components/header-page";
import { Layout } from "../components/layout";
import usePlataforma from "../hooks/usePlataforma";
import { toast } from "react-toastify";
import { useState } from "react";
import useFetchGetMetas from "../queries/meta";
import { CardMeta } from "../components/CardMeta";

export const Metas = () => {
  const { data: dataMetas, isLoading: isLoadingMetas } = useFetchGetMetas()
  const [resultadoDia, setResultadoDia] = useState(dataMetas ? dataMetas.meta.resultadoDia : '');
  const [resultadoSemana, setResultadoSemana] = useState(dataMetas ? dataMetas.meta.resultadoSemana : '');
  const [resultadoMes, setResultadoMes] = useState(dataMetas ? dataMetas.meta.resultadoMes : '');
  const { addMeta } = usePlataforma()


  const changeResultadoDia = (event) => {
    setResultadoDia(event.target.value)
  }
  
  const changeResultadoSemana = (event) => {
    setResultadoSemana(event.target.value)
  }

  const changeResultadoMes = (event) => {
    setResultadoMes(event.target.value)
  }

  const handleAlterar = () => {
    addMeta(resultadoDia, resultadoSemana, resultadoMes)
    toast.success("Meta alterada com sucesso!")
  }


  return (
    <Layout isLoading={isLoadingMetas}>
      <HeaderPage title="Alterar Metas" />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mt: 4 }}
      >
        <TextField type="number" value={resultadoDia} onChange={changeResultadoDia} label="Meta dia" variant="outlined" />
        <TextField type="number" value={resultadoSemana} onChange={changeResultadoSemana} label="Meta Semana" variant="outlined" />
        <TextField type="number" value={resultadoMes} onChange={changeResultadoMes} label="Meta mês" variant="outlined" />
        <Button onClick={handleAlterar} size="large" variant="contained">Alterar</Button>
      </Stack>
      <Box sx={{ mt: 3 }} >
        <CardMeta progress={dataMetas ? dataMetas.porcentagemDia : 0} valor={dataMetas ? dataMetas.meta.resultadoDia : 0} title="do dia" />
      </Box>
      <Box sx={{ mt: 2 }} >
        <CardMeta progress={dataMetas ? dataMetas.porcentagemSemana : 0} valor={dataMetas ? dataMetas.meta.resultadoSemana : 0} title="da semana" />
      </Box>
      <Box sx={{ mt: 2, mb: 1 }} >
        <CardMeta progress={dataMetas ? dataMetas.porcentagemMes : 0} valor={dataMetas ? dataMetas.meta.resultadoMes : 0} title="do mês" />
      </Box>
    </Layout>
  );
}
