import { Box, Divider, Drawer, Typography, useMediaQuery, Tooltip, Badge, Stack} from '@mui/material';
import { NavItem } from './nav-item.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import useStore from '../providers/useStore';

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LeaderboardIcon from '@mui/icons-material/Leaderboard'; // rank
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const icons  = {
  dashboard: <DisplaySettingsIcon fontSize="small" />,
  rank: <LeaderboardIcon fontSize="small" />,
  parcelas: <ReceiptIcon fontSize="small" />,
  meta: <CrisisAlertIcon fontSize="small" />,
  usuarios: <PeopleAltIcon fontSize="small" />,
  grafico: <QueryStatsIcon fontSize="small" />,
}

const menu = [
  {
    href: '/',
    icon: icons['dashboard'],
    title: 'Dashboard',
    iconNew: false
  },
  {
    href: '/parcelas',
    icon: icons['parcelas'],
    title: 'Parcelas',
    iconNew: false
  },
  {
    href: '/rank',
    icon: icons['rank'],
    title: 'Classificação',
    iconNew: false
  }
];

const menuAdmin = [
  {
    href: '/meta',
    icon: icons['meta'],
    title: 'Metas',
    iconNew: false
  },
  {
    href: '/usuarios',
    icon: icons['usuarios'],
    title: 'Usuários',
    iconNew: false
  }
];


export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const user = useStore((state) => state.user)
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box>
          <Box sx={{ p: 3 }}>
              <Box sx={{ml: 3, maxWidth: 180}}>
                <img src="/logo.png" alt="logo" style={{
                  width: '100%',
                }}/>
              </Box>
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1
              }}
            >
              <Box>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1.5}
              >
              <Box>
              <Tooltip title={`Olá ${user ? user.nome : ""}, seja bem-vindo(a)!`} arrow>
                <Typography
                  color="inherit"
                  variant="subtitle1"
                >
                 {user ? user.nome : ""} 
                </Typography>
                </Tooltip>
                </Box>
                <Badge color="secondary" badgeContent=" " variant="dot"/>
              </Stack>
                <Typography
                  color="neutral.400"
                  variant="body2"
                >
                  Setor Financeiro
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 1
          }}
        />
        <PerfectScrollbar>
        <Box sx={{ flexGrow: 1, mb: 1 }}>
        {menu.map((item) => (
            <NavItem 
            key={item.title}
            icon={item.icon}
            href={item.href}
            title={item.title}
            iconNew={item.iconNew}
          />
          ))}
          {user && user.isAdmin && <Divider sx={{ borderColor: '#2D3748', my: 1 }} /> }
          {user && user.isAdmin && menuAdmin.map((item) => (
            <NavItem 
            key={item.title}
            icon={item.icon}
            href={item.href}
            title={item.title}
            iconNew={item.iconNew}
          />
          ))}
        </Box>
        </PerfectScrollbar>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
